// import packages
import React, {useEffect, useRef, useState} from 'react';

//Import Components

//Import assets
import "./NewsModal.scss"

// Import utils
import {connect} from "react-redux";
import {getPropsFromState} from "../../../../redux/mapStateToProps";
import {Modal} from "antd";
import {generateImageMediaUrl} from "../../../../utils/generateMediaUrl";
import moment from "moment";
import {EllipseIcon, ModalCloseIcon} from "../../../../assets/images";
import Slider from "react-slick";


function NewsModal(props){
    const {modalOpen,toggleModalOpen,news,setModalOpen,staticTexts} = props
    const [imgList ,setImgList] = useState([])
    let sliderRef = useRef(null);


    useEffect(() => {
        if (news) {
            const list = [
                news?.mediaMain?.path,
                ...(news?.medias?.map(item => item?.path) || [])
            ];
            setImgList(list);
        }
    }, [news]);

    function closeModal(){
        setImgList([]);
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
        toggleModalOpen();
    }

    
    const settings = {
        dots: true,
        fade: true,
        arrows: false,
        autoplay:false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: sliderRef,
    };

    return <Modal centered
                  title={''}
                  open={modalOpen}
                  closeIcon={<ModalCloseIcon/>}
                  className={'newsModal'}
                  maskClosable={true}
                  onCancel={closeModal}>
        <div className={'news-modal-wrapper'}>
            <Slider {...settings}>
                {imgList?.length && imgList.map((item,index) =>{
                    return <div className={'news-block-img'} key={index}>
                        <img src={generateImageMediaUrl(item)}/>
                    </div>
                })}
            </Slider>
            <div className={'news-block-info'}>
                <div className={'news-date'}>
                    {moment(news?.date).format('ll')}
                </div>
                <div className={'news-block-title'}>
                    {news?.title}
                </div>
                <div className={'news-block-description'} dangerouslySetInnerHTML={{__html: news?.description}}/>
                {
                    news?.link && <a href={news?.link} className={'news_link'} target={'_blank'}>
                        {staticTexts?.news_page_link}
                    </a>
                }
            </div>
        </div>
    </Modal>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(NewsModal)